.gains {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;

  .gains_image {
    width: 900px;
    height: 500px;
  }
}

.yeti {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 70px;

  .yeti_image {
    width: 900px;
    height: 600px;
  }

  .project_text {
    margin-left: 50px;
  }
}

.dao {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 100px;

  .dao_image {
    width: 900px;
    height: 500px;
  }

  .project_text {
    margin-left: 50px;
  }
}

.passxyz {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 70px;

  .passxyz_image {
    width: 700px;
    height: 600px;
  }
}

.project_text {
  background: linear-gradient(
    59.82deg,
    #3c3c3c 0%,
    rgba(243, 238, 238, 0) 49.36%,
    rgba(0, 220, 231, 0) 50.11%,
    #1e2224 100%
  );
  color: #fff;
  font-family: monospace;
  font-weight: 700;
  height: fit-content;
  border-radius: 8px;
  padding: 15px;
  max-width: 400px;

  a {
    color: inherit;
  }

  .title {
    font-size: 28px;
  }

  .project_description {
    margin-top: 15px;
    font-size: 20px;
    text-align: start;
  }
}

@media screen and (max-width: 1300px) {
  .gains {
    .gains_image {
      width: 500px;
      height: 250px;
    }
  }

  .yeti {
    .yeti_image {
      width: 500px;
      height: 300px;
    }
  }

  .dao {
    .dao_image {
      width: 500px;
      height: 250px;
    }
  }

  .passxyz {
    .passxyz_image {
      width: 400px;
      height: 350px;
    }
  }

  .project_text {
    .title {
      font-size: 20px;
    }

    .project_description {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 900px) {
  .gains {
    flex-direction: column-reverse;
    justify-content: center;

    .gains_image {
      width: 80%;
      height: initial;
    }
  }

  .yeti {
    flex-direction: column;
    justify-content: center;

    .yeti_image {
      width: 80%;
      height: initial;
    }
  }

  .dao {
    flex-direction: column;
    justify-content: center;

    .dao_image {
      width: 80%;
      height: initial;
    }
  }

  .passxyz {
    flex-direction: column-reverse;
    justify-content: center;

    .passxyz_image {
      width: 80%;
      height: initial;
    }
  }

  .project_text {
    margin: 10px 0 0 !important;
    width: 80%;

    .title {
      font-size: 18px;
    }

    .project_description {
      font-size: 14px;
      line-height: 150%;
    }
  }
}

.cosmos-loader {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
}

.star {
  width: 100px;
  height: 100px;
  background: radial-gradient(circle at center, #ffcc00, #ff9900);
  border-radius: 50%;
  animation: spin 30s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.orbit {
  position: absolute;
  transform-origin: center;
  animation-duration: linear infinite;

  .planet {
    border-radius: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.mercury {
  width: 120px;
  height: 120px;
  animation: orbitRotate 5s linear infinite;

  .planet {
    width: 8px;
    height: 8px;
    background: grey;
    transform-origin: 140px;
  }
}

.venus {
  width: 150px;
  height: 150px;
  animation: orbitRotate 8s linear infinite;

  .planet {
    width: 15px;
    height: 15px;
    background: #f5deb3;
    transform-origin: 180px;
  }
}
.earth {
  width: 180px;
  height: 180px;
  animation: orbitRotate 10s linear infinite;

  .planet {
    width: 16px;
    height: 16px;
    background: #1e90ff;
    transform-origin: 220px;
  }
}
.mars {
  width: 200px;
  height: 200px;
  animation: orbitRotate 13s linear infinite;

  .planet {
    width: 12px;
    height: 12px;
    background: #ff4500;
    transform-origin: 260px;
  }
}
.jupiter {
  width: 250px;
  height: 250px;
  animation: orbitRotate 17s linear infinite;

  .planet {
    width: 35px;
    height: 35px;
    background: #d2b48c;
    transform-origin: 300px;
  }
}

.saturn {
  width: 300px;
  height: 300px;
  animation: orbitRotate 20s linear infinite;

  .planet {
    width: 30px;
    height: 30px;
    background: #f4a460;
    transform-origin: 340px;
  }
}

.uranus {
  width: 350px;
  height: 350px;
  animation: orbitRotate 23s linear infinite;

  .planet {
    width: 22px;
    height: 22px;
    background: #add8e6;
    transform-origin: 380px;
  }
}

.neptune {
  width: 380px;
  height: 380px;
  animation: orbitRotate 26s linear infinite;

  .planet {
    width: 21px;
    height: 21px;
    background: #0000ff;
    transform-origin: 420px;
  }
}

@keyframes orbitRotate {
  0% {
    transform: rotate(0deg) translateX(0);
  }
  100% {
    transform: rotate(360deg) translateX(0);
  }
}

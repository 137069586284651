.section {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    background-color: #222;
    color: #ddd;
    width: 90%;
    max-width: 960px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .section h1 {
    color: #4af;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 8px;
  }

  .homepage {
    background-color: #222;
  }
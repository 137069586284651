.header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  z-index: 100000;
  padding: 20px;

  .pages {
    display: flex;
    gap: 20px;

    a {
      height: 20px;
    }
  }

  .page {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.mobile_menu {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 100000000;
  justify-content: flex-end;
  display: flex;
  overflow: hidden;

  .menu_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }

  .menu_content {
    height: 100%;
    width: 280px;
    padding: 0 10px;
    position: fixed;
    z-index: 1000000000;
    animation: menu_animation 0.5s;

    .close_icon {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0 60px;
    }

    .social_links {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 50px;
      font-size: 12px;

      a {
        height: 20px;
      }
    }

    .pages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;

      .mobile_page {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

@keyframes menu_animation {
  from {
    transform: translate(280px, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

.projects_container {
  background-image: url("../../../public/images/venus_bg.webp");
  background-position: 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  color: #f3f3f3;
  min-height: 100vh;
  background-attachment: fixed;

  .alient {
    position: fixed;
    width: 40px;
    height: 50px;
    top: 38%;
    left: 50px;
  }

  .content {
    z-index: 10;
    margin-top: 25px;
    position: relative;

    .description {
      width: 90%;
      max-width: 800px;
      margin: 30px auto 0;
      font-size: 18px;
      text-align: start;
      line-height: 180%;
      border-radius: 8px;
      padding: 10px 20px;
      background-size: cover;
      font-weight: 700;
      font-family: monospace;
      background: linear-gradient(
        53deg,
        #3c3c3c 0%,
        rgb(116 116 116 / 21%) 49.36%,
        rgb(116 116 116 / 24%) 50.11%,
        #1e2224 100%
      );
    }
  }

  @media screen and (max-width: 900px) {
    .content {
      .description {
        width: 85%;
        font-size: 16px;
      }
    }
  }
}

.contact_container {
  background-image: url("../../../public/images/mars_bg.webp");
  background-position: 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  color: #f3f3f3;
  min-height: 100vh;
  background-attachment: fixed;

  .form_content {
    width: 90%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    margin: 100px 50px 0 auto;
    background: linear-gradient(
      59.82deg,
      #3c3c3c 0%,
      rgba(243, 238, 238, 0) 49.36%,
      rgba(0, 220, 231, 0) 50.11%,
      #1e2224 100%
    );

    .page_title {
      font-size: 30px;
      color: #fff; // Dark text color for contrast
      margin: 20px 0;
      font-weight: bold;
    }

    .description {
        max-width: 700px;
        text-align: start;
        font-size: 20px;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      width: 70%;

      input,
      textarea {
        position: relative;
        cursor: text;
        font-size: 14px;
        line-height: 20px;
        padding: 0 16px;
        height: 48px;
        background-color: transparent;
        border: 1px solid #d6d6e7;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-radius: 3px;
        color: #f3f3f3;
        box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 30%);
        overflow: hidden;
        transition: all 100ms ease-in-out;
        outline: none !important;
        width: 100%;
        margin-bottom: 15px;
        
        &::placeholder {
            color: #b9b8b8;
        }
      }

      textarea {
        height: 100px;
        resize: none;
      }
    }

    button {
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      max-width: 200px;
      background-color: #7e7eab;
      color: #fff;
      font-weight: 700;
      font-size: 18px;
      border: none;
      border-radius: 16px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken(#7e7eab, 10%); // Darkens the button on hover
      }
    }

    p {
      font-size: 14px;
      color: #f3f3f3;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 1200px) {
    .form_content {
      margin: 100px auto 0;
      width: 85%;

      .description {
        font-size: 18px;
      }

      .form {
        width: 90%;
      }
    }

  }
}

.about_me_container {
  background-image: url("../../../public/images/earth_bg_3.webp");
  background-position: 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  color: #f3f3f3;
  min-height: 100vh;
  background-attachment: fixed;

  .text_container {
    max-width: 900px;
    background: linear-gradient(
      59.82deg,
      #8a9e4f 0%,
      rgb(80 112 75 / 67%) 49.36%,
      rgb(87 117 80 / 65%) 50.11%,
      #5f7e5f 100%
    );
    border-radius: 16px;
    padding: 15px;
    margin: 0 auto;
    margin-top: 100px;
    text-align: start;
    font-family: sans-serif;
    position: relative;
    z-index: 100000;

    .text_content {
      display: flex;
      align-items: center;
      gap: 20px;

      .text_hi,
      .text_developer {
        font-size: 20px;
        margin-bottom: 7px;
      }

      .text_name {
        font-size: 25px;
        margin-bottom: 10px;
        font-weight: 700;
      }

      .text_description {
        line-height: 160%;
      }

      .avatar {
        width: 300px;
        height: 300px;
      }
    }

    .buttons {
      margin-top: 16px;
      display: flex;
      gap: 20px;

      .resume {
        padding: 10px 15px;
        border-radius: 8px;
        background-color: rgb(79 70 229);
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #f3f3f3;
        text-decoration: none;
      }

      .contact {
        padding: 10px;
        border-radius: 8px;
        background-color: #5dba2f;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          margin-bottom: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .text_container {
      max-width: 500px;
      width: 90%;

      .text_content {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

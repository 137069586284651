.menu {
  position: absolute;
  top: 10px;
  right: 30px;
  font-family: "Minecraft";
  color: #fff; //#48d2d2

  .title {
    font-size: 25px;
  }

  .items {
    div {
        min-height: 40px;
        margin: 10px 0;

      p {
        text-align: start;
        font-size: 18px;
        cursor: pointer;
        padding: 5px;
        max-height: 35px;
        margin: 0;

        &::before {
          content: "\2606"; // Outlined star
          width: 15px;
          height: 15px;
          color: #fff;
          margin-right: 10px;
        }

        &:hover {
          border: 1px solid #fff;

          &::before {
            content: "\2605";
          }
        }
      }
      .activeItem {
        border: 1px solid #fff;

        &::before {
          content: "\2605"; // Solid star
        }
      }
    }
  }
}

.skills_container {
  background-image: url("../../../public/images/mercury_bg.webp");
  background-position: 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  color: #f3f3f3;

  .content {
    z-index: 10;
    margin-top: 25px;
    
    .description {
      max-width: 900px;
      width: 90%;
      margin: 0 auto;
      font-size: 18px;
      text-align: start;
      line-height: 180%;
      border-radius: 8px;
      padding: 10px 20px;
      background-size: cover;
      font-weight: 700;
      font-family: monospace;
      background: linear-gradient(
        59.82deg,
        #3c3c3c 0%,
        rgba(243, 238, 238, 0) 49.36%,
        rgba(0, 220, 231, 0) 50.11%,
        #1e2224 100%
      );
    }

    .skils_container {
      font-family: cursive;
      max-width: 900px;
      width: 90%;
      margin: 0 auto;
      font-weight: 700;

      .skils_fragment {
        margin: 20px 0;

        .title {
          text-align: start;
          font-size: 30px;
          margin-bottom: 15px;
        }

        .skils_items {
          display: flex;
          column-gap: 10px;
          row-gap: 10px;
          flex-wrap: wrap;
        }

        .skill {
          position: relative;
          width: 120px;
          height: 120px;

          .hexagon-shape {
            position: absolute;
            overflow: hidden;
            background: transparent;
            width: 100.28px;
            height: 100.28px;
            transform: rotate(-30deg) skewX(30deg) scaleY(0.866);

            &::before {
              position: absolute;
              right: 6.7%;
              bottom: 0;
              left: 6.7%;
              top: 0;
              transform: scaleY(1.155) skewX(-30deg) rotate(30deg);
              background: linear-gradient(
                59.82deg,
                #fff2f2 0%,
                rgb(243 238 238 / 0%) 49.36%,
                rgba(0, 220, 231, 0) 50.11%,
                #1e2224 100%
              );
              content: "";
            }
          }

          .skill_box {
            cursor: default;
            width: 100px;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .skill_image {
              width: 40px;
              height: 40px;
            }
          }

          &:hover {
            .hexagon-shape,
            .skill_box {
              width: 110px;
              height: 110px;
              transition: 0.5s;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .content {
      .description {
        width: 85%;
        font-size: 16px;
      }

      .skils_container {
        width: 85%;

        .skils_fragment {
          .skils_items {
            gap: 0;

            .skill {
              width: 100px;
              height: 100px;

              .hexagon-shape {
                width: 80px;
                height: 80px;
              }

              .skill_box {
                width: 80px;
                height: 80px;

                .name {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
